@import './assets/flags/flags.css';

.floatlabel-demo {
    .p-field {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}
.card-margin-top {
    margin-top: 0.05rem !important;
}
.input-demo {
    .p-multiselect {
        min-width: 15rem;
    }

    .multiselect-custom {
        .country-item {
            display: flex;
            align-items: center;

            span.flag {
                width: 18px;
                height: 12px;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }
        }

        .country-item-value {
            border-radius: 3px;
            display: inline-flex;
            vertical-align: top;
            margin-right: 0.5rem;
            padding-right: 0.5rem;
            background-color: #2196f3;
            color: #ffffff;
        }
    }
}

.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
            -ms-flex: 1 1 0px;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    .product-grid-item {
        margin: 0.5em;
        border: 1px solid var(--surface-d);

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.media-demo {
    .product-item {
        .product-item-content {
            border: 1px solid var(--surface-d);
            border-radius: 3px;
            margin: 0.3rem;
            text-align: center;
            padding: 2rem 0;
        }

        .product-image {
            width: 50%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
}

.menu-demo {
    .stepsdemo-content {
        padding: 1em 0;

        p {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
        }

        i {
            vertical-align: middle;
            font-size: 1.5em;
            margin: 0;
        }
    }

    .contextmenu-image {
        width: 100%;
    }
}

.messages-demo {
    .p-field > label {
        width: 125px;
    }

    .p-inputtext {
        margin-right: 0.5rem;
    }
}

.misc-demo {
    .badges {
        .p-badge,
        .p-tag {
            margin-right: 0.5rem;
        }
    }

    .p-button {
        margin-right: 0.5rem;
    }

    .p-overlay-badge {
        margin-right: 2rem;
    }

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: #2196f3;
        }

        40% {
            stroke: #ec407a;
        }

        66% {
            stroke: #66bb6a;
        }

        80%,
        90% {
            stroke: #ffa726;
        }
    }
}

.overlay-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

.panel-demo {
    .p-toolbar {
        flex-wrap: wrap;
        overflow: auto;
    }

    p {
        line-height: 1.5;
        margin: 0;
    }

    .p-card .p-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
    }
}

.crud-demo {
    .table-header {
        flex-direction: column;

        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row !important;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--surface-d);

                    > td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.icons-demo {
    .icon-filter {
        width: 100%;
        padding: 1rem;
        margin: 1rem 0 1.5rem 0;
    }

    .icons-list {
        text-align: center;
        color: var(--text-color);

        .p-md-2 {
            padding: 1em;
        }
    }

    .icons-list i {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: var(--text-color-secondary);
    }
}

pre[class*='language-'] {
    &:before,
    &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-b) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {
            &.tag,
            &.keyword {
                color: #2196f3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196f3 !important;
            }

            &.attr-value {
                color: #4caf50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string,
            &.entity,
            &.url,
            &.variable {
                background: transparent;
            }
        }
    }
}

.timeline-demo {
    .custom-marker {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }

    .p-timeline-event-content,
    .p-timeline-event-opposite {
        line-height: 1;
    }

    @media screen and (max-width: 960px) {
        .customized-timeline {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;

                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .fc-toolbar {
        &.fc-header-toolbar {
            flex-wrap: wrap;
        }
    }
}

.block-viewer {
    .block-section {
        margin-bottom: 4rem;
        overflow: hidden;
    }

    .block-header {
        padding: 1rem 2rem;
        background-color: var(--surface-section);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border: 1px solid var(--surface-d);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block-title {
            font-weight: 700;
            display: inline-flex;
            align-items: center;

            .badge-free {
                border-radius: 4px;
                padding: 0.25rem 0.5rem;
                background-color: var(--orange-500);
                color: white;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 0.875rem;
            }
        }

        .block-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            margin-left: 1rem;

            a,
            button {
                display: flex;
                align-items: center;
                margin-right: 0.75rem;
                padding: 0.5rem 1rem;
                border-radius: 4px;
                font-weight: 600;
                border: 1px solid transparent;
                transition: background-color 0.2s;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:not(.block-action-disabled):hover {
                    background-color: var(--surface-c);
                }

                &.block-action-active {
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                }

                &.block-action-copy {
                    i {
                        color: var(--primary-color);
                        font-size: 1.25rem;
                        margin: 0;
                    }
                }

                &.block-action-disabled {
                    opacity: 0.6;
                    cursor: auto !important;
                }

                i {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .block-content {
        padding: 0;
        border: 1px solid var(--surface-d);
        border-top: 0 none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;
    }

    pre[class*='language-'] {
        margin: 0 !important;

        &:before,
        &:after {
            display: none !important;
        }

        code {
            border-left: 0 none !important;
            box-shadow: none !important;
            background: var(--surface-e) !important;
            margin: 0;
            color: var(--text-color);
            font-size: 14px;
            padding: 0 2rem !important;

            .token {
                &.tag,
                &.keyword {
                    color: #2196f3 !important;
                }

                &.attr-name,
                &.attr-string {
                    color: #2196f3 !important;
                }

                &.attr-value {
                    color: #4caf50 !important;
                }

                &.punctuation {
                    color: var(--text-color);
                }

                &.operator,
                &.string {
                    background: transparent;
                }
            }
        }
    }

    @media screen and (max-width: 575px) {
        .block-header {
            flex-direction: column;
            align-items: start;

            .block-actions {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }
}

.customer-badge,
.product-badge,
.order-badge {
    border-radius: var(--border-radius);
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.customer-badge {
    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.product-badge {
    border-radius: var(--border-radius);
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: var(--border-radius);
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.table-demo {
    .p-datatable-frozen-tbody {
        font-weight: bold;
    }

    .p-datatable-scrollable {
        .p-frozen-column {
            font-weight: bold;
        }
    }

    .image-text {
        vertical-align: middle;
        margin-left: 0.5rem;
    }
}

.light_dark_theme .p-radiobutton-box {
    display: none;
}

.product-store-content .p-fieldset-legend {
    border: none;
    border-radius: unset;
    background: none;
}

.product-store-content .p-fieldset-content {
    padding: 0px;
}

.product-settings-tab .p-tabmenu .p-tabmenu-nav {
    border: none;
    background: none;
}

.product-settings-tab .p-menuitem-link {
    background: var(--surface-50) !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.datatable_container .p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.p-datatable-emptymessage td {
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.layout-content {
    padding-top: 4.5rem;
    padding-bottom: 10px;
}

.row_expand_show_title .p-datatable-thead {
    position: unset;
}

.row_expand_show_title .p-column-title {
    display: block !important;
}

.breadcrumb-container .p-breadcrumb {
    background: unset;
    border: none;
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
}

.select-button-content .p-button.p-highlight {
    background: #607d8b;
    border-color: #607d8b;
}

.select-button-content .p-button.p-highlight:hover {
    background: #607d8b;
    border-color: #607d8b;
}

// .product-settings-tab .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
//     border-color: #673AB7;
//     color: #673AB7;
//     outline: 0 none;
//     outline-offset: 0;
//     box-shadow: inset 0 0 0 0.2rem #c1ade5;
// }

.swiper-slide {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
}

.swiper-slide img {
    height: 155px;
    width: 155px;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.swiper-button-prev::after {
    font-size: 35px !important;
}

.swiper-button-next::after {
    font-size: 35px !important;
}

.swiper-pagination {
    position: unset !important;
    font-size: 18px !important;
    font-weight: bolder !important;
}

.product-map-search-content .p-autocomplete {
    display: unset;
    position: unset;
}

.product-map-search-content .p-autocomplete .p-inputtext {
    width: 100%;
}

// .p-dropdown-panel {
//     z-index: 996 !important;
// }

// .availability-status-content .p-dropdown-panel {
//     z-index: 1002 !important;
// }

.cross-content.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #495057 !important;
    border-color: transparent !important;
    background: #e9ecef !important;
}

.hide-spinner-content i.p-autocomplete-loader.pi.pi-spinner.pi-spin {
    display: none !important;
}

.login-btn {
    margin-top: 15px;
    height: 40px !important;
    background-color: #214e8f;
    margin-bottom: 5px;
}

.login-btn:hover {
    background: #a6c9e2 !important;
    color: #214e8f !important;
    // border-color: #214E8F !important;
}

.login-name,
.login-email,
.login-pass,
.p-password-input {
    width: 100%;
    height: 40px !important;
    margin: 5px 0px;
    text-align: left;
    // padding: 5px !important;
    color: black;
}

.form-label-pass {
    position: relative;
    top: 6px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-email,
.p-password-input {
    padding: 5px !important;
}

.login-card-div {
    display: flex;
    align-items: center !important;
    justify-content: center;
    height: 100vh;
}

.login-card {
    background-color: #fff;
    background-clip: initial;
    border: 1px solid rgba(34, 41, 47, 0.125);
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: var(--surface-card);
    border-radius: 10px;
    margin-bottom: 1rem;
    width: 400px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

$auth-1-inner-max-width: 400px !default;

.auth-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;

    .auth-inner {
        width: 100%;
        position: relative;
    }

    &.auth-v1 {
        align-items: center;
        justify-content: center;
        overflow: hidden; // For v1 scroll for long auth form

        .auth-inner {
            &:before {
                width: 244px;
                height: 243px;
                content: ' ';
                position: absolute;
                top: -54px;
                left: -46px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC');
            }

            &:after {
                width: 272px;
                height: 272px;
                content: ' ';
                position: absolute;
                bottom: -55px;
                right: -75px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARAAAAEQCAMAAABP1NsnAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABEKADAAQAAAABAAABEAAAAAAQWxS2AAAAwFBMVEUAAAD///+AgICAgP9VVaqqVf+qqv+AgL+AgP9mZsxmZv+ZZv+AgNWAgP9tbdttbf+Sbf+AYN+AgN+AgP9xceNmZv+AZuaAZv90dOh0dP9qav+AauqAav+AgP92dv9tbf+Abe2Abf93Zu53d+6AcO94afCAcfF5a+R5a/JzZuaAZvKAc/J5bed5bfOAaPN6b/R1auqAavR6ZvV6cPV2bOuAbPV7aPZ2be2AbfZ7au17avZ3Zu53b+57a+97a/d4aO9J6CoeAAAAQHRSTlMAAQICAwMDBAQFBQUGBgcHBwgICAkKCgoLCwwMDAwNDg4ODw8QERITExQUFBUVFhcYGBkZGhobHBwdHR4eHx8gJ5uMWwAAA/FJREFUeNrt2G1XEkEYxvHZNk2xHGzdbKFl0cTwgdSkCKzu7/+t4pw6sAjtjIueE/f8r3fMO35nZnbuy5gVGcvfzJe0rnTfGI+MggGJRUZnbpPIhJKt88nU53JnFULvyISY6KAv8vPj0vr2rYwiE2Z2B9J+uNYcyyQxwWZvaeGH3G4bMjsvI/kcwTC/V+7kLoahlITzQojP3ZFgsJCh7IJQzpX0QFj4uMiY18eDMZ9bZCF9OQahnK6cm/Y7js0sh/LF3Auv1PlQd3MxbdXYIQspV44EEEAAAWTNDAYYkKdJbNMsLzYueZbaZ2iM46RVbHBaiZ9Js+nHEdli42N9XuSen5hGp1CQTuOJQDRsD99N4gMSpYWapNH6IJo83CIeILZQFesEaber79NCWRoukOpNEnW0gXQqD81w6ACxhbrYde7VuFCYeA2QRCNIsgZISyNIqz6IyhPjOjNVIFYniK3dmKU6QdLaJUimEySrDZLrBMlrgxRKU7sxCw/EMe0CAggggADySJCqxixIkKpNEh6IozELD8RxjQACCCCAAPJIkKrGLEgQXqqAAEJjxrQLCCCAAEJjRmNGY8a0CwgggABCYwYIfQgggNCYMe0CAggggNCY0ZjRmDHtAgIIIIAAQmNGHwIIIDRmTLuAAAIIIDRmNGY0Zky7gAACCCCA0JjRhwACCI0Z0y4ggAACCI0ZjRmNGdMuIIAAAgggNGb0IYAAQmPGtAsIIIAAQmNGY0ZjxrQLCCCAAAIIjRl9CCCA0Jgx7QICCCCA0JjRmNGYMe0CAggggABCY0YfAgggNGZMu4AAAgggNGY0ZjRmTLuAAAIIIIDQmNGHAAIIjRnTLiCAAAIIjRmNGY0ZIEy7gAACCCA0ZvQhgABCY8a0CwgggABCY0ZjBgiNGdMuIIAAAgiN2f/Sh+Q6PfLaIJlOkKw2SKoTJK3dmFmdILb2tBvrBIlrg5iWRo+WqQ+SaARJ1gCJAzsxThCN16p1vNurGjNjoo42j07kAHFskoY2kEbl33U0ZgoPjXW+Rl0gkarnahqtDaJKxMPDDWIiNafGenh4gExvVhXfmk7Da6L1AVGxSby2h6MxK79Zk42ea1pJbJ48sU2zDezQ8iy1z6BBwoyjMQsvXp8YQAAhgADilRfyy+wf8WqZZUfGZihvgZiB3FybC+kCUU5XLkAo50C+gbBQdUzkAIVyejIAYfFTI1solHP2HgNCnHn5AYNy4jvpoVB6fVzL91cwzLJ9Lfd7S0jhehxO5H5/yePr1W6gHonI7fJ5ORSR/n6Q2yQanq763zuXU5LJZRKiyD/W9/pjkdPZz0/yJ8fqVyry+qQZDMjJKoDfy8bRVhHhQTwAAAAASUVORK5CYII=');
                z-index: -1;
            }
        }
    }

    &.auth-v2 {
        align-items: flex-start;

        .auth-inner {
            height: 100vh;
            overflow-y: auto; // For v2 scroll for long auth form
            height: calc(var(--vh, 1vh) * 100);
        }

        .brand-logo {
            position: absolute;
            top: 2rem;
            left: 2rem;
            margin: 0;
            z-index: 1;
        }
    }

    &.auth-v1 .auth-inner {
        max-width: $auth-1-inner-max-width;
    }

    .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 2rem 0;

        .brand-text {
            font-weight: 600;
        }
    }

    .auth-footer-btn {
        .btn {
            padding: 0.6rem !important;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}

.banner-text-content .p-inputtext {
    width: 25% !important;
    height: 3rem !important;
}

.p-colorpicker-panel {
    transform: translate(4%, -40px) !important;
    left: 40% !important;
}

.panel-image-content .p-panel-content {
    display: none !important;
}

.p-password-input {
    margin: 0px 0px !important;
}

.send-feed-icon .p-button-icon {
    font-size: 17px;
    color: #008000;
}

.trash-icon .p-button-icon {
    font-size: 17px;
    color: #ff0000;
}

.reset-icon .p-button-icon {
    font-size: 17px;
}
.header-title {
    color: #000000 !important;
}
.column-row-title {
    color: #000000 !important;
    font-weight: normal !important;
}
.product-select-content {
    border: 1px solid #e9ecef;
    border-bottom: none;
    border-width: 1px;
    background: #ffff;
}
.dropdown-color-content .p-dropdown-trigger {
    background: #214e8f !important;
    color: #fff !important;
    width: 2rem !important;
}

.status-draft-text {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #000000;
}

.status-text {
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid #000000;
}

.b2b-status-text {
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #000000;
}

.stoneShare-datatable-content .p-datatable-thead > tr > th {
    background: #f5f5f5 !important;
}
.p-checkbox .p-checkbox-box {
    border: 2px solid #757575 !important;
}

.b2b-order-details-card .p-card-content {
    padding: 0px !important;
}

.timeline-content .p-timeline-event-opposite {
    flex: 0 !important;
    padding: 0px !important;
}

.b2b-order-details-card .p-card {
    border: 1px solid #f7f8fb !important;
    border-radius: 15px !important;
}

.b2b-order-details-card .p-card-body {
    padding: 0px !important;
}

.b2b-order-details-card .p-card-title {
    padding: 1rem !important;
    border-bottom: 1px solid #eaedef !important;
    font-weight: normal !important;
    margin-bottom: 0px !important;
}

.b2b-order-details-card .p-card-content {
    padding: 1rem !important;
}

.b2b-order-details-content .invoice-items table tr:last-child {
    border-bottom: none !important;
}

@media (min-width: 1200px) {
    .auth-wrapper {
        &.auth-v2 .auth-card {
            width: $auth-1-inner-max-width;
        }
    }
}
